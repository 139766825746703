import React, { Component } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseUnAuthed
} from "@react-firebase/auth";

import { Layout, Button } from 'antd';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import config from './config'
import './App.css';
import Users from './Users'
import User from './User'
const {
  Header, Content,
} = Layout;

class App extends Component {
  constructor(props){
    super(props);
    ReactGA.initialize('UA-121693825-2');
  }

  render() {
    return (

      <FirebaseAuthProvider {...config} firebase={firebase}>
        <Layout>
          <Header>
            <IfFirebaseUnAuthed>
              <Button
                onClick={() => {
                  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                  firebase.auth().signInWithPopup(googleAuthProvider);
                }}
                icon={'login'}
                size={'small'}
              >
                Sign In with Google
        </Button>
            </IfFirebaseUnAuthed>
            <IfFirebaseAuthed>
              <Button 
                onClick={() => {
                  firebase.auth().signOut();
                }}
                icon={'logout'}
                size={'small'}
              >
                Sign Out
        </Button>
              <FirebaseAuthConsumer>
                {({ user }) => {
                  return (
                    <span style={{ color: "white", margin: "10px" }}>Welcome {user.displayName}</span>
                  );
                }}
              </FirebaseAuthConsumer>
            </IfFirebaseAuthed>
            <Button
              icon={'download'}
              size={'small'}

              href='https://firebasestorage.googleapis.com/v0/b/gater-74379.appspot.com/o/gater_client%2Fgater_client_v1.2.apk?alt=media&token=53275daa-608e-4979-98ae-1cb44d12a39b'
            />
          </Header>
          <Content>
            <FirebaseAuthConsumer>
              {({ user, isSignedIn }) => {
                // return (<Users user={user} isSignedIn={isSignedIn} />)

                return (
                  <BrowserRouter>

                    <Switch>
                      <Route exact path="/" render={() => (<User user={user} isSignedIn={isSignedIn} />)} />
                      <Route path="/admin" render={() => (<Users user={user} isSignedIn={isSignedIn} />)} />
                    </Switch>
                  </BrowserRouter>

                )

              }}
            </FirebaseAuthConsumer>
          </Content>
        </Layout>
      </FirebaseAuthProvider>

    );
  }
}

export default App;
