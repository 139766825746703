import React, { Component } from 'react';
import firebase from "firebase/app";
import "firebase/database";
import { FirebaseDatabaseProvider, FirebaseDatabaseNode } from "@react-firebase/database";
import { Table, Checkbox, Button, List, notification } from 'antd';
import ReactGA from 'react-ga';
import config from './config'
const moment = require('moment');

const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
}, {
    title: 'Authorized',
    key: 'auth',
    render: (text, record) => (
        <FirebaseDatabaseNode path={`/user-permissions/${record.uid}`}>
            {({ path, value }) => {
                return (
                    <Checkbox
                        checked={(value || {}).authorized}
                        onChange={async (e) => {
                            const firebaseRef = firebase
                                .app()
                                .database()
                                .ref(path);
                            try {
                                await firebaseRef.update({ authorized: e.target.checked })

                            } catch (error) {
                                notification.open({
                                    message: 'Update failed',
                                    description: error.message,
                                });
                            }
                        }} />
                )
            }}

        </FirebaseDatabaseNode>
    ),
}, {
    title: 'Delete',
    key: 'delete',
    render: (text, record) => (
        <Button
            icon={'delete'}
            onClick={async (e) => {
                const firebaseRef = firebase
                    .app()
                    .database()
                    .ref(`/users/${record.uid}`);
                try {
                    await firebaseRef.set(null)

                } catch (error) {
                    notification.open({
                        message: 'Update failed',
                        description: error.message,
                    });
                }
            }}
        />
    )
}
];


export default class Users extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }
    render() {
        const { user, isSignedIn } = this.props;
        console.log(user);
        return (
            !isSignedIn ? <span>You are not signed it</span> :
                <FirebaseDatabaseProvider {...config} firebase={firebase}>
                    <FirebaseDatabaseNode
                        path='/users'

                    >
                        {({ path, value }) => {
                            const users = Object.entries(value || {}).map(([k, v]) => ({
                                name: v.displayName,
                                uid: k,
                                authorized: v.authorized
                            }))
                            return (<Table
                                dataSource={users}
                                columns={columns}
                                expandedRowRender={(record) => (
                                    <FirebaseDatabaseNode
                                        path={`user-commands/${record.uid}`}
                                        limitToLast={100}
                                        orderByValue={"time"}
                                    >
                                        {d => {
                                            const times = Object.entries(d.value || {}).map(([k, v]) => ({
                                                time: new Date(v.time)
                                            })).reverse();
                                            return (<List
                                                size='small'
                                                dataSource={times}
                                                renderItem={item => (<List.Item>{moment(item.time).format('dddd, MMMM Do YYYY, HH:mm')}</List.Item>)}
                                            ></List>)
                                        }}
                                    </FirebaseDatabaseNode>
                                )}
                            ></Table>);
                        }}
                    </FirebaseDatabaseNode>
                </FirebaseDatabaseProvider>
        )
    }
}