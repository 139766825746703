import React, { Component } from 'react';
import firebase from "firebase/app";
import "firebase/database";
import { FirebaseDatabaseProvider, FirebaseDatabaseNode } from "@react-firebase/database";
import { List, Affix, Button, notification } from 'antd';
import ReactGA from 'react-ga';

import config from './config'
const moment = require('moment');

export default class Users extends Component {

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.isSignedIn && this.props.isSignedIn !== prevProps.isSignedIn) {
            this._registerUser(this.props.user);
        }
    }

    async _registerUser(user) {
        if (!user) {
            return;
        }
        const path = `users/${user.uid}`
        const firebaseRef = firebase
            .app()
            .database()
            .ref(path);
        try {
            await firebaseRef.update({ displayName: user.displayName });

        } catch (error) {
            notification.open({
                message: 'Failed to register user',
                description: error.message,
            });
        }
    }
    render() {
        const { user, isSignedIn } = this.props;
        return (
            !isSignedIn ? <span>You are not signed it</span> :
                <FirebaseDatabaseProvider {...config} firebase={firebase}>
                    <FirebaseDatabaseNode
                        path={`user-commands/${user.uid}`}
                        limitToLast={100}
                        orderByValue={"time"}
                    >
                        {d => {
                            const times = Object.entries(d.value || {}).map(([k, v]) => ({
                                time: new Date(v.time)
                            })).reverse();
                            return (
                                <div>
                                    <Affix offsetTop={10}>
                                        <Button
                                            type='primary' block
                                            size='large'
                                            onClick={async () => {
                                                const path = `user-commands/${user.uid}`
                                                const firebaseRef = firebase
                                                    .app()
                                                    .database()
                                                    .ref(path);
                                                try {
                                                    ReactGA.event({action: 'open'});
                                                    await firebaseRef.push({ time: firebase.database.ServerValue.TIMESTAMP })

                                                } catch (error) {
                                                    notification.open({
                                                        message: 'Open failed',
                                                        description: error.message,
                                                    });
                                                }
                                            }}
                                        >
                                            Open Sesame Now3
                                    </Button>
                                    </Affix>
                                    <List style={{ padding: '10px', height: '30px' }}
                                        size='small'
                                        dataSource={times}
                                        renderItem={item => (<List.Item>{moment(item.time).format('dddd, MMMM Do YYYY, HH:mm')}</List.Item>)}
                                    ></List>
                                </div>)
                        }}
                    </FirebaseDatabaseNode>
                </FirebaseDatabaseProvider >
        )
    }
}